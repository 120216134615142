import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useStyles } from './ModalNewTask';
import HeaderModal from './blocks/HeaderModal';
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   bgcolor: 'background.paper',
   boxShadow: 24,
   borderRadius: '10px',
   p: 3,
};
const ModalTermsOfUse = ({ showTermsOfUse, setShowTermsOfUse }) => {
   const classes = useStyles();
   return (
      <div>
         <Modal
            open={showTermsOfUse}
            onClose={() => setShowTermsOfUse(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ border: 'none' }}>
            <Box
               style={{ maxHeight: '60%', overflow: 'auto', paddingTop: 0 }}
               sx={(theme) => {
                  return { ...style };
               }}>
               <Box className={classes.root}>
                  <HeaderModal
                     title={'կայքի ծառայության օգտագործման պայմաններ'}
                     close={() => setShowTermsOfUse(false)}
                  />
               </Box>
               <Box>
                  <Typography>1. Ընդհանուր պայմաններ</Typography>
                  <Typography>
                     1.1. Սույն փաստաթղթի նպատակների համար օգտագործվում են հետևյալ տերմինները
                  </Typography>
                  <Typography>
                     Օգտատեր՝ ինտերնետ օգտագործող, ով օգտագործում է Ծառայության ֆունկցիոնալությունը
                     և դրա անհատական ​​գործառույթները.
                  </Typography>
                  <Typography>
                     Ծառայությունների սպասարկում - կայքի ծառայություն, որը հասանելի է (մուտքագրեք
                     հասցեն) և այլ ռեսուրսների վրա, Պայմաններ - կայքի ծառայության օգտագործման սույն
                     Պայմանները հրապարակված են հասցեում (մուտքագրեք հասցեն)
                  </Typography>
                  <Typography>
                     Կատարող - օգտատեր, ով կայքում գովազդ է տեղադրում իր ծառայությունների մասին կամ
                     պատասխանում է պատվերներին.
                  </Typography>
                  <Typography>
                     Ծառայություն - ապրանքների սպասարկում, աշխատանք, առք ու վաճառք և առաքում, որոնց
                     համար օգտատերը տեղադրել է գովազդ
                  </Typography>
                  <Typography>
                     Գործընկեր – անձ, որը պայմանագրային հարաբերություններ է հաստատել կայքի հետ'
                     կապված գործընկերոջ առեւտրային գործունեության մեջ ծառայության օգտագործման հետ'
                     հաճախորդներին գրավելու նպատակով, ինչպես նաեւ այլ նպատակներով:
                  </Typography>
                  <Typography>
                     Դիմում - դիմում հաճախորդի (օգտատիրոջ) կողմից՝ դրանում նշված պայմաններով
                     ծառայություն մատուցելու անհրաժեշտության մասին: Հավելվածը կարող է հրապարակվել
                     կայքի էջում և հասանելի է անորոշ թվով մարդկանց, ովքեր նշել են ծառայության այս
                     ոլորտը իրենց կարգավորումներում, կամ կարող են ուղարկվել ծառայության
                     ֆունկցիոնալության միջոցով՝ կապված հատուկ ծառայության առաջարկի հետ։
                  </Typography>
                  <Typography>
                     Հաճախորդ՝- ծառայության օգտատեր, ով ծառայության մեջ ծառայությունների հարցումներ
                     է ներկայացնում կամ որոշակի ուղղությամբ մասնագետ է փնտրում.
                  </Typography>
                  <Typography>
                     Պատասխան՝- հաճախորդին ուղղված հաղորդագրություն մասնագետից՝ տվյալ առաջադրանքի
                     կատարման ժամանակի և արժեքի մասին տեղեկություններով
                  </Typography>
                  <Typography>աշիվ – կայքի օգտվողի հաշիվ;</Typography>
                  <Typography>
                     Հանրային էջ - կատարողի էջ, որը հասանելի է կայքում անսահմանափակ թվով մարդկանց
                     համար, որը պարունակում է տեղեկություններ կատարողի մասին, ներառյալ նրա
                     կոնտակտային տվյալները, նրա մատուցած ծառայությունների մասին տեղեկություններ,
                     աշխատանքային փորձ, ծառայության հատուկ նշաններ, վարկանիշներ, վարկանիշ:
                     ծառայության վրա, անձնական լուսանկար, քաղաքային կացարան և այլն;
                  </Typography>
                  <Typography>
                     Բովանդակություն – ցանկացած տեքստ, լուսանկար, վիդեո նյութեր, որոնք օգտատերը
                     վերբեռնում է ծառայություն կամ իր կողմից ավտոմատ կերպով փոխանցվում ծառայությանը:
                  </Typography>
                  <Typography>
                     2. կայքը ցանկացած օգտվողի հրավիրում է օգտվել այս ինտերնետային կայքից՝ սույն
                     Պայմաններին համապատասխան:
                  </Typography>
                  <Typography>
                     3. Այս ինտերնետային կայքի օգտագործումը ենթակա է սույն դրույթներին և
                     պայմաններին:
                  </Typography>
                  <Typography>
                     4. Օգտագործելով Կայքը և նրա անհատական ​​գործառույթները՝ օգտատերը համարվում է,
                     որ ընդունել է այս պայմանները ամբողջությամբ՝ առանց որևէ վերապահումների կամ
                     բացառությունների: Եթե ​​Օգտատերը համաձայն չէ այս փաստաթղթերի դրույթներից որևէ
                     մեկի հետ, Օգտատերը իրավունք ունի չօգտագործել Կայքը:
                  </Typography>
                  <Typography>
                     5. Սույն դրույթներն ու պայմանները ենթակա են փոփոխման առանց ծանուցման: Նոր
                     պայմանները ուժի մեջ են մտնում համացանցում տեղադրվելու պահից։ Պայմանների
                     փոփոխություններից հետո Կայքի շարունակական օգտագործումը համարվում է նոր
                     փոփոխությունների ընդունում:
                  </Typography>
                  <Typography>
                     6. Օգտագործելով Կայքը՝ օգտատերը համաձայնում է ստանալ տեղեկատվական կամ
                     գովազդային հաղորդագրություններ:
                  </Typography>
                  <Typography>
                     7. Կայքը հաճախորդներին հնարավորություն է ընձեռում տեղադրելու, որոնելու և
                     դիտելու գովազդները և կատարողների որոնումը, իսկ կատարողները՝ պատասխանելու
                     պատվերներին, ինչպես նաև այլ հնարավորություններ կայքի բոլոր օգտագործողների
                     համար:
                  </Typography>
                  <Typography>
                     8. Ծառայության ֆունկցիոնալությունը Օգտատիրոջը կարող է հասանելի լինել կայքում
                     գրանցվելուց հետո՝ պայմաններին համապատասխան:
                  </Typography>
                  <Typography>
                     9. Օգտատերը լիազորումից հետո իրավունք ունի Կայքում ստեղծել հաշիվ, որից հետո
                     կարող է ստեղծել գովազդներ, առաջադրանքներ տեղադրել ծառայության համար, պատասխանել
                     հավելվածներին, ավելացնել իր պրոֆիլում տեղեկատվություն, խմբագրել իր պրոֆիլը:
                  </Typography>
                  <Typography>
                     Դուք չեք կարող ստեղծել մի քանի հաշիվ: Եթե ​​օգտատերն ունի լրացուցիչ հաշիվ, ապա
                     այս օգտատերը կարող է ընդմիշտ արգելափակվել Կայքի ծառայությունից՝ առանց
                     նախազգուշացման կամ չեղյալ համարվել:
                  </Typography>
                  <Typography>
                     10. Կայքը գրանցված օգտվողին հնարավորություն է տալիս հաղորդագրություններ գրել
                     այս ինտերնետային կայքի մեկ այլ օգտատիրոջը, հնարավորություն է տալիս քննարկումներ
                     անցկացնել կատարողների միջև կատարողների, ինչպես նաև հաճախորդների միջև:
                  </Typography>
                  <Typography>
                     Որակի հսկողություն իրականացնելու, կատարողի կողմից հաճախորդին ծառայությունների
                     մատուցման փաստը և պայմանները ստուգելու նպատակով, օրինական պահանջների պահպանման
                     շրջանակներում, կայքը պահպանում է հաճախորդի և կատարողի միջև փոխգործակցության
                     պատմությունը:
                  </Typography>
                  <Typography>
                     11. Հաճախորդը հնարավորություն ունի հրապարակելու ակնարկներ և միավորներ տալ
                     կատարողին: Վերանայումը պետք է արտահայտի հաճախորդի գնահատականը իրեն մատուցված
                     ծառայությունների կամ կատարողի հետ փոխգործակցության վերաբերյալ, վերանայումը և
                     վարկանիշը պետք է պարունակեն արժանահավատորեն նշված փաստական ​​հանգամանքներ:
                     կատարողի հետ կապվելու փորձ, առանց վիրավորանքի: Հաճախորդը կարող է հրապարակել
                     ակնարկ միայն իր անունով:
                  </Typography>
                  <Typography>
                     12. Կայքը կարող է ստուգել կապալառուի հուսալիությունը Կայքում հաշիվ ստեղծելիս
                     կատարողի կողմից տրամադրված տվյալների հիման վրա: Ստուգումը կարող է ներառել բաց
                     աղբյուրների և տվյալների բազաների օգտագործումը, ինչպես նաև երրորդ անձանց
                     ներգրավումը:
                  </Typography>
                  <Typography>
                     կատարողի հուսալիության ստուգման արդյունքների հիման վրա, ինչպես նաև փաստաթղթեր
                     տրամադրելուց կատարողի մերժման դեպքում, Կայքը կարող է հրաժարվել կապալառուին
                     մուտք գործել կայք՝ առանց մերժման պատճառների բացատրության: .
                  </Typography>
                  <Typography>
                     13. Կայքում լրացնելով կատարողի անձնական հաշիվը, ստացված գումարն ուղարկվում է
                     Կայքում կատարողի անձնական հաշվին և կարող է օգտագործվել հաճախորդների
                     առաջադրանքներին պատասխանելու համար, կամ վերադարձվում է կատարողի խնդրանքով,
                     հավասար է փաստացի գումարին վերադարձի հայտի պահին:
                  </Typography>
                  <Typography>
                     Կայքը հնարավորություն ունի գնահատելու կատարողի գործունեությունը կայքի կողմից
                     սահմանված չափանիշների հիման վրա, ինչպիսիք են՝ պատասխանների հաճախականությունը,
                     ծառայության վերաբերյալ հաճախորդի հետ համաձայնության հաստատումը, կատարված
                     պատվերների հաստատումը և փակումը, հաճախորդներին արձագանքելու արագությունը։
                     կատարողի վարկանիշը, որը որոշվում է հաճախորդների ակնարկների և գնահատականների
                     հիման վրա և այլն:
                  </Typography>
                  <Typography>
                     կատարողի գնահատականները հասանելի են կատարողի անձնական աքաունթում: Կայքի բոլոր
                     օգտվողները կարող են տեսնել այն:
                  </Typography>
                  <Typography>
                     Ինչպես նաև կայքի ադմինիստրատորները հնարավորություն կունենան որոշ ժամանակով
                     արգելել կատարողներին։
                  </Typography>
                  <Typography>
                     14. Կայքը թույլ է տալիս կատարողներին պատասխանել հաճախորդներից ստացվող
                     դիմումներին: Երբ կատարողն ավարտում է պատասխանը և առաջարկում է պատվերի կատարման
                     պայմանները, հաճախորդը ստանում է առաջարկ՝ բանակցություններ վարել այս և այն
                     պայմաններով ծառայության մատուցման վերաբերյալ, այսինչ գնով: Հաճախորդը,
                     ուսումնասիրելով բոլոր առաջարկները, որոշում է, թե որ մասնագետին ընտրի որպես
                     կատարող։
                  </Typography>
                  <Typography>
                     Պատասխաններ կատարելիս կատարողնին արգելվում է չարաշահել Կայքի ծառայության
                     ֆունկցիոնալությունը կամ օգտագործել ցանկացած ծրագիր կամ բոտ, որը թույլ է տալիս
                     ավտոմատ կերպով պատասխաններ տեղադրել:
                  </Typography>
                  <Typography>
                     Կկատարողի հաշիվը կարող է պարունակել տեղեկատվություն Կայքի ծառայության միջոցով
                     կատարված պատվերների քանակի մասին:
                  </Typography>
                  <Typography>
                     15. Կայքի ծառայությունում տեղադրելով մասնագետի քարտը՝ կատարողն համաձայնում է,
                     որ իր տրամադրած տեղեկատվությունը, ներառյալ կոնտակտային տվյալները, անձնական
                     լուսանկարը, բովանդակությունը հասանելի կլինեն անսահմանափակ թվով մարդկանց ամբողջ
                     աշխարհում:
                  </Typography>
                  <Typography>
                     Կայքը չի կարող երաշխավորել, որ այս տեղեկատվությունը կարող է օգտագործվել երրորդ
                     անձանց կողմից, և պատասխանատվություն չի կրում երրորդ անձանց գործողությունների
                     համար՝ օգտատիրոջ կողմից տրամադրված տեղեկատվությունը Ծառայության օգտագործման հետ
                     չկապված նպատակների համար պատճենելու և մշակելու համար: Տեղադրելով կոնտակտային
                     տվյալները՝ օգտատերը համաձայնում է, որ Կայքի ծառայությունը ձայնագրի
                     խոսակցությունը և հեռախոսահամարը, որից կատարվել է զանգը: Զանգերի
                     ձայնագրությունները կարող են օգտագործվել Կայքի ծառայության որակը վերահսկելու,
                     օգտվողների բողոքները դիտարկելու և մասնագետների կողմից մատուցվող
                     ծառայությունների որակը վերահսկելու համար:
                  </Typography>
                  <Typography>
                     16. կատարողն, Կայքի ծառայությունում մասնագետի քարտ ստեղծելիս, պարտավորվում է
                     պատասխանել միայն իր կողմից մատուցվող ծառայությունների վերաբերյալ, նա չի կարող
                     անձնավորել այլ անձի, պատվիրատուներից հանձնարարել առաջադրանքներ, վերավաճառել,
                     փոխանակել Կայքում ստացված կամ ընդունված պատվերները: սպասարկում ինչպես վճարովի,
                     այնպես էլ անվճար հիմունքներով։
                  </Typography>
                  <Typography>
                     17. Օգտատերը ինքնուրույն և ամբողջությամբ կրում է բոլոր ռիսկերը և
                     պատասխանատվությունը օրենքին, բովանդակությանը, ամբողջականությանը, ճշգրտությանը և
                     Կայքի ծառայությունում իր կողմից տեղադրված տեղեկատվության պահպանման համար:
                  </Typography>
                  <Typography>
                     Արգելվում է օգտագործել կայքը և նրա գործառույթները հատուկ չթույլատրված
                     գովազդային տեղեկատվության վերբեռնման, տեղադրման, փոխանցման, տարածման, տարբեր
                     փոստերի, սպամի, վիրուսներ կամ այլ վնասակար համակարգչային կոդեր պարունակող
                     նյութերի, ֆայլերի կամ ծրագրերի համար, օգտագործելով կայքը բացառապես օգտվողներին
                     այլ կայքերի էջեր վերահղելու համար:
                  </Typography>
                  <Typography>
                     Կայքն իրեն իրավունք է վերապահում, առանց նախազգուշացման և իր հայեցողությամբ,
                     հեռացնել կայքում հրապարակված ցանկացած գովազդ, տեղեկատվություն կամ սահմանափակել
                     օգտատերերի մուտքը կայք, ներառյալ, եթե օգտատիրոջ գործողությունները,
                     բովանդակության հրապարակումը կամ այլ տեղեկատվություն հակասում են Հայաստանի
                     Հանրապետության օրենքները, այս պայմանները, ինչպես նաև հրապարակված նյութերի համար
                     կայքի պահանջները:
                  </Typography>
                  <Typography>
                     18. Կայքը բոլոր օգտատերերին տալիս է ցանկացած ոլորտի մասնագետ փնտրելու պատվերներ
                     կատարելու անվճար հնարավորություն։
                  </Typography>
                  <Typography>
                     Հաճախորդը սույնով ծանուցվում է և համաձայնում է, որ եթե նա դիմում է ներկայացնում
                     Կայքի հարթակի միջոցով մասնագետ փնտրելու համար, ապա իր կողմից տրամադրված ողջ
                     տեղեկատվությունը կարող է փոխանցվելկատարողի կողմից ծառայություն մատուցելու համար
                     նշանակված ներգրավված կատարողներին՝ հետադարձ կապի նպատակով: ծառայությունների
                     մատուցումը և հայտի փաստացի իրականացումը։
                  </Typography>
                  <Typography>
                     Հաճախորդին ծառայությունները մատուցվում են կատարողի կողմից Կայքից, սակայն Կայքը
                     այն հարաբերությունների կողմ չէ, որով կատարողն ծառայություններ է մատուցում
                     հաճախորդին, և չի կարող պատասխանատվություն կրել մատուցվող ծառայությունների
                     որակի, ժամանակին և ամբողջականության համար, ինչպես նաև կատարողնի
                     գործողությունների համար:
                  </Typography>
                  <Typography>
                     Կայքը խորհուրդ է տալիս բոլոր հաճախորդներին և Կայքի միջոցով մասնագետներ
                     փնտրողներին մասնագետ ընտրելուց հետո չանել կանխիկ կանխիկացում, այլ նախ ամեն ինչ
                     մանրամասն քննարկել, համաձայնեցնել և գրավոր պայմանագիր կնքել մասնագետի և
                     հաճախորդի միջև: Կայքից դուրս հաճախորդի և կատարողի միջև բոլոր կանխիկ և անկանխիկ
                     վճարումները կատարվում են կողմերի կողմից ուղղակիորեն առանց Կայքի մասնակցության:
                  </Typography>
                  <Typography>
                     19. Կայքում նշված ծառայությունների, պայմանների և դրանց տրամադրման արժեքի մասին
                     նշված տեղեկատվությունը միայն հղման համար է: Ծառայության վերջնական արժեքը պետք է
                     ճշտվի ընտրված մասնագետի հետ:
                  </Typography>
                  <Typography>
                     Կատարողների կողմից մատուցվող ծառայությունների հետ կապված բոլոր պահանջները,
                     հարցերը, դժգոհությունները հաճախորդը պետք է ուղարկի հենց կատարողի հասցեին՝
                     օգտագործելով կատարողի քարտում նշված ցանկացած հասանելի կոնտակտային տվյալ:
                  </Typography>
                  <Typography>
                     Կայքը չի կարող երաշխավորել կայքի 100% համապատասխանությունը օգտատիրոջ
                     նպատակներին և ակնկալիքներին, կամ կայքի իդեալական և առանց սխալների աշխատանքը:
                     Ցանկացած գրանցված օգտատեր պետք է ինքնուրույն գնահատի տեղեկատվության օգտագործման
                     հետ կապված բոլոր ռիսկերը, ներառյալ հավաստիության, ամբողջականության կամ
                     օգտակարության գնահատումը, ինչպես նաև դրանց համապատասխանությունը օրենքին:
                  </Typography>
                  <Typography>
                     Կայքը հաճախորդի և կատարողի միջև ծառայությունների մատուցման վերաբերյալ հաստատված
                     իրավահարաբերությունների մասնակից չէ, դրանում միջնորդական գործառույթներ չի
                     իրականացնում, պատասխանատվություն չի կրում կողմերի կողմից իրենց
                     պարտականությունների կատարման համար և խորհուրդ չի տալիս. իրավական, ֆինանսական և
                     կազմակերպչական հարցեր:
                  </Typography>
                  <Typography>
                     Հաճախորդը և կատարողը պարտավորվում են ինքնուրույն լուծել բոլոր վեճերը, որոնք
                     ծագում են առաջարկվող և հայցվող ծառայությունների վերաբերյալ գործարքի կնքման և
                     կատարման հետ կապված, ներառյալ պահանջներ ուղարկելը ուղղակիորեն նման գործարքի
                     մասնակցին:
                  </Typography>
                  <Typography>
                     20. Կայքն իրավունք է վերապահում իր հայեցողությամբ սահմանափակել կատարողի մուտքը
                     կայք՝ օգտագործելով իր հաշիվը կամ ամբողջությամբ արգելափակել հաշիվը, եթե կայքում
                     կասկածելի գործունեություն է հայտնաբերվել, ինչպես նաև այլ միջոցներ կիրառել
                     կատարողի նկատմամբ՝ համապատասխանելու համար։ այս պայմանների պահանջներով։
                  </Typography>
                  <Typography>
                     Կայքն իրեն իրավունք է վերապահում սահմանափակել հաճախորդի մուտքը կայք կամ
                     արգելափակել հաճախորդի պրոֆիլը կայքում, եթե կայքում հայտնաբերվի կասկածելի
                     գործունեություն:
                  </Typography>
                  <Typography>
                     Կայքում օգտագործվող ցանկացած տեղեկատվություն նախատեսված է բացառապես անձնական,
                     ոչ առևտրային օգտագործման համար: Արգելվում է կայքից տեղեկատվության ցանկացած
                     պատճենումը, վերարտադրումը, մշակումը, տարածումը, ցանկացած օգտագործում
                     լրատվամիջոցներում կամ առևտրային նպատակներով՝ առանց Կայքի նախնական գրավոր
                     թույլտվության:
                  </Typography>
                  <Typography>
                     Ցանկացած օգտատեր համաձայնում է, որ չի օգտագործի Կայքում տեղադրված
                     տեղեկատվությունը և բովանդակությունը առանց Կայքի թույլտվության, այն դեպքերում,
                     երբ այդպիսի օգտագործումը կապված չէ գործարքների կատարման և կատարման նպատակների
                     հետ: Արգելվում է օգտագործել կատարողի կամ պատվիրատուի կոնտակտային տվյալները՝
                     առանց կատարողի կամ պատվիրատուի համաձայնության: Կայքի օգտագործման կամ
                     օգտագործման անկարողության, ինչպես նաև Կայքում օրենքի կամ երրորդ անձանց
                     իրավունքների հնարավոր խախտման հետ կապված հարցերի և պահանջների դեպքում օգտատերը
                     կարող է իր հարցումն ուղարկել ****@gmail.com էլ.փաստին
                  </Typography>
                  <Typography style={{ textAlign: 'right' }}>
                     Հրապարակման ամսաթիվ՝ 6 հունիսի 2022թ
                  </Typography>
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                     <Button
                        color="success"
                        variant="contained"
                        onClick={() => setShowTermsOfUse(false)}>
                        համաձայն եմ
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Modal>
      </div>
   );
};

export default ModalTermsOfUse;
