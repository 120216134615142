import * as React from "react";

const MessengerSvg = () => {
    return (
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 12C0 5.373 6.26762 0 14 0C21.7315 0 28 5.373 28 12C28 18.627 21.7306 24 14 24C13.0751 24 12.1739 23.918 11.2997 23.771C10.7013 24.603 8.12525 27.474 5.39087 27.986C5.39271 27.9802 5.3539 27.9844 5.3047 27.9896C5.26006 27.9943 5.20688 28 5.16775 28C4.72938 28 4.375 27.594 4.375 27.094C4.375 26.967 4.39862 26.846 4.43975 26.736H4.438C4.50679 26.6008 4.58727 26.4492 4.6751 26.2839C5.24527 25.2102 6.125 23.5537 6.125 22C6.125 21.9852 6.12681 21.9733 6.12859 21.9616C6.13031 21.9503 6.132 21.9393 6.132 21.926C2.43162 19.766 0 16.127 0 12ZM11.5535 21.793C12.3743 21.93 13.1976 22 14 22C20.7541 22 26.25 17.514 26.25 12C26.25 6.486 20.7533 2 14 2C7.245 2 1.75 6.486 1.75 12C1.75 15.207 3.6855 18.252 6.92825 20.144C7.51363 20.486 7.882 21.173 7.882 21.925C7.882 21.98 7.87937 22.045 7.87412 22.109C7.85575 23.143 7.58887 24.146 7.24762 25.041C8.50675 24.186 9.5725 23.023 9.947 22.502C10.2821 22.036 10.78 21.772 11.2997 21.772C11.3837 21.772 11.4686 21.777 11.5535 21.793ZM4.375 12C4.375 8.206 8.78238 5 14 5C14.2415 5 14.4375 5.224 14.4375 5.5C14.4375 5.776 14.2406 6 14 6C9.33887 6 5.25 8.804 5.25 12C5.25 12.276 5.054 12.5 4.8125 12.5C4.571 12.5 4.375 12.276 4.375 12Z"
                    fill="#616161"
                />
            </svg>
    );
};
export default MessengerSvg;
