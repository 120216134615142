import * as React from "react";

const DownloadFileSvg2 = () => {
  return (
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10V11C1 12.1046 1.89543 13 3 13H13C14.1046 13 15 12.1046 15 11V10" stroke="#808080" strokeLinecap="round"/>
      <path d="M8.5 1C8.5 0.723858 8.27614 0.5 8 0.5C7.72386 0.5 7.5 0.723858 7.5 1H8.5ZM7.64645 11.3536C7.84171 11.5488 8.15829 11.5488 8.35355 11.3536L11.5355 8.17157C11.7308 7.97631 11.7308 7.65973 11.5355 7.46447C11.3403 7.2692 11.0237 7.2692 10.8284 7.46447L8 10.2929L5.17157 7.46447C4.97631 7.2692 4.65973 7.2692 4.46447 7.46447C4.2692 7.65973 4.2692 7.97631 4.46447 8.17157L7.64645 11.3536ZM7.5 1V11H8.5V1H7.5Z" fill="#808080"/>
      </svg>

  );
};
export default DownloadFileSvg2;
