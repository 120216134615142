import Box from "@mui/material/Box";
import { useStyles } from "../UI/modals/ModalNewTask";
import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { CustomTypography } from "../documentsPage/DocumentsPage";

const TermsOfUsePage = ({ status = "terms_of_use" }) => {
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  //   Privacy policy

  return (
    <div style={{ marginTop: auth ? "120px" : "30px" }}>
      <Container maxWidth="lg">
        <Box style={{ maxHeight: "60%", overflow: "auto", paddingTop: 0 }}>
          <Box sx={{ display: "flex" }}>
            <CustomTypography onClick={() => navigate("/")}>
              Հիմնական
            </CustomTypography>
            <Typography>Օգտվելու կանոններ</Typography>
          </Box>
          {status === "public_offer" ? (
            <>
              <Typography
                pt={2}
                pb={2}
                component="h1"
                variant="h4"
                sx={{
                  fontWeight: "500",
                  textAlign: "center",
                  "& span": {
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "23px",
                  },
                }}
              >
               ՀԱՍԱՐԱԿԱԿԱՆ ԱՌԱՋԱՐԿ
                <span>
                «Անվտանգ գործարք» ծառայության (այսուհետ՝ ԱՌԱՋԱՐԿ) տրամադրման պայմանների մասին.
                </span>
              </Typography>
              <Typography mt={1}>
                В настоящей Оферте содержатся условия предоставления ИП Сунейкин
                Пётр Владимирович услуг, возникающих при использовании сервиса
                «Безопасная сделка» на указанных в настоящей Оферте условиях.
                <br /> Изложенный ниже текст Оферты является официальным
                публичным предложением адресованным физическим лицам, являющимся
                Пользователями  Gorc-ka - http://Gorc-ka.am/ <br /> Акцепт
                условий настоящей Оферты осуществляется в форме конклюдентных
                действий, предусмотренных Офертой, и означает безоговорочное
                принятие физическим лицом всех условий настоящей Оферты без
                каких-либо изъятий или ограничений на условиях присоединения
                согласно ст. 437 и ст. 428 Гражданского кодекса Российской
                Федерации.
                <br /> Акцепт условий настоящей оферты подтверждает факт
                заключения Договора об использовании сервиса «Безопасная
                сделка».
                {/* <br /> Актуальная версия Оферты размещена в сети Интернет по
                адресу: */}
              </Typography>
              <Box>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                </Typography>
                <Typography mt={1}>
                  В настоящем документе термины, написанные с заглавной буквы,
                  имеют следующее значение:
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Авторизация</span> – процедура запроса и получения
                  Банком ответа на такой запрос в виде разрешения или запрета на
                  проведение операции по Карте от Банка-эмитента Карты или от
                  Платежной системы.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Банк</span> – Акционерное общество «Тинькофф Банк»
                  (лицензия ЦБ РФ № 2673, ОГРН 1027739642281, местонахождение:
                  123060, Москва, 1-ый Волоколамский проезд, д.10, стр.1),
                  предоставляющее Пользователям Услуги Банка на условиях,
                  предусмотренных настоящей Офертой.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Банк-эмитент</span> – кредитная организация,
                  осуществляющая эмиссию Карты.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Верификация</span> – процедура дополнительной проверки
                  Банком Держателя карты и Карты, осуществляемая с целью
                  снижения рисков проведения несанкционированной/незаконной
                  операции по Карте отправителя.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Держатель Карты</span> – Пользователь, на имя которого
                  Банком или Банком-эмитентом эмитирована Карта.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Личный кабинет</span> — персональный раздел Пользователя
                  на Сайте/ Приложении, связанный с Учетной записью
                  Пользователя, в котором Пользователю доступно управление
                  Сервисом.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Общество</span> — ИП Сунейкин П.В, ОГРН 322190000001167,
                  обеспечивающее использования Сервиса Пользователями.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Объявление</span> — информационное сообщение с
                  предложением о Товаре (включая контактную информацию,
                  фотографии и любую сопутствующую информацию), размещаемое
                  Продавцом на Сайте/Приложении, адресованное неопределенному
                  кругу лиц.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Платежная страница Банка</span> — интерфейс Банка,
                  который размещен на Сайте/Приложении, используемый
                  Пользователем для ввода реквизитов Карты и составлении, и
                  передаче Поручения.{" "}
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Пользователь</span> — дееспособное физическое лицо,
                  зарегистрированное на Сайте/Приложении, и присоединившееся к
                  настоящей Оферте, а также заказчик и исполнитель при
                  совместном упоминании.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Заказчик</span> — Пользователь Сайта/Приложения,
                  зарегистрированный на Сайте/, размещающий в сервисе заявки на
                  оказание услуг, или поиск специалиста в определённом
                  направлении
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Поручение</span> — направленные в Банк посредством
                  использования Сайта /Приложения и Платежной страницы Банка
                  акцепт Пользователя, содержащий информацию о присоединении к
                  настоящей оферте и распоряжение Пользователя на совершение
                  Банком перевода на основании предоставленной Клиентом и
                  Обществом информации.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Исполнитель</span> — пользователь, размещающий в сервисе
                  объявление о своих услугах, либо осуществляющий отклики на
                  заказы.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Gorc-ka - сайт</span>, приложение, представляющее
                  собой совокупность программного обеспечения Общества,
                  устанавливаемого на мобильное устройство (телефон и/или
                  планшетный компьютер, и/или портативный плеер и/или иное
                  устройство, поддерживающие установку такого программного
                  обеспечения) Пользователя, предоставляющее возможность
                  размещения Исполнителями Объявлений, предоставления услуг, а
                  также предоставляющий Заказчикам возможность поиска, просмотра
                  предложений Исполнителей с целью предоставления услуг с
                  использованием Сервиса.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Сайт</span> — интернет ресурс Общества, размещенный в
                  сети интернет по адресу http://ligauslug.ru/, предоставляющий
                  возможность размещения исполнителями Объявлений об оказании
                  услуг, а также предоставляющий Заказчикам возможность поиска,
                  просмотра предложений Исполнителей об услугах.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Сервис «Безопасная сделка» (Сервис)</span> - сервис,
                  позволяющий Заказчику и Исполнителю при исполнении ими своих
                  обязательств по Сделке использовать услуги, оказываемые Банком
                  и Обществом в соответствии с настоящей Офертой, в порядке,
                  предусмотренном настоящей Офертой.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Сделка</span> – соглашение, заключенное между Заказчиком
                  и Исполнителем, в порядке, предусмотренном настоящей Офертой,
                  предметом которого является поиск и подбор Заказчиками
                  Исполнителей для оказания услуг с использованием Сервиса.
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <span>Учетная запись Пользователя</span> - хранимая Обществом
                  совокупность данных о Пользователе, предоставляющая собой
                  комбинацию уникального логина и пароля, используемая для
                  аутентификации Пользователя при входе в Личный кабинет с целью
                  получения доступа к Сервису.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  2. ОБЩИЕ ПОЛОЖЕНИЯ
                </Typography>
                <Typography mt={1}>
                  2.1. Общество и Банк предлагает Пользователям на условиях
                  настоящей Оферты воспользоваться Сервисом.
                </Typography>
                <Typography mt={1}>
                  2.2. В рамках Сервиса Общество оказывает услуги по
                  предоставлению Пользователям функциональности
                  Сайта/Приложения, обеспечивающей возможность автоматической
                  оплаты исполнителями Стоимости откликов на заявки от клиентов,
                  в безналичном порядке с использованием Карты (ее реквизитов),
                  а также услуги по обмену информацией между клиентом и
                  исполнителем, с целью заключения и исполнения Сделки,
                  совершенной в рамках использования Сервиса. Расчеты в рамках
                  Сервиса осуществляются Банком на условиях настоящей Оферты.
                </Typography>
                <Typography mt={1}>
                  2.3. Настоящая Оферта считается акцептованной Пользователем в
                  момент совершения Пользователем соответствующих действий:
                </Typography>
                <Typography mt={1}>
                  2.3.1. Настоящая Оферта считается акцептованной исполнителем в
                  момент выбора Заказчиком нужного Исполнителя, в рамках Сделки
                  с использованием Сервиса путем нажатия специальной кнопки в
                  интерфейсе Сайта/Приложения;
                </Typography>
                <Typography mt={1}>
                  2.4. Фиксация факта акцепта настоящей Оферты осуществляется
                  Обществом в электронном виде и хранится в
                  аппаратно-программном комплексе Общества. Выписки из
                  аппаратно-программного комплекса Общества могут использоваться
                  в качестве доказательств при рассмотрении споров, в том числе
                  в судебном порядке.
                </Typography>
                <Typography mt={1}>
                  2.5. Сайт является площадкой, позволяющей Исполнителям
                  самостоятельно на свой страх и риск размещать предложения,
                  адресованные неопределенному кругу лиц на совершение сделки в
                  отношении оказания услуг, которым исполнитель правомочен
                  распоряжаться (делать предложения), Заказчикам принимать на
                  свое усмотрение и под свою ответственность предложения,
                  выбирать одного Исполнителя из всех откликнувшихся на
                  оставленное задание Заказчиком, размещенное на Сайте, заключая
                  соответствующую сделку с Исполнителем.
                  <br />
                  При заключении Сделки Заказчик вступает в прямые договорные
                  отношения с Исполнителем, которого сам выбрал. Общество не
                  является стороной по Сделке, организатором Сделки, Заказчиком,
                  Исполнителем, посредником, агентом или представителем
                  какого-либо Пользователя и/или иным заинтересованным лицом в
                  отношении предлагаемой/заключаемой между Пользователями
                  Сделки. Все совершаемые Сделки между Пользователями
                  заключаются и исполняются без прямого или косвенного участия
                  Общества.
                </Typography>
                <Typography mt={1}>
                  2.6. Осуществляя доступ к Сервису и заключая договоры, путем
                  акцепта настоящей Оферты, Пользователь гарантирует, что
                  обладает всеми правами и полномочиями, необходимыми для
                  заключения и исполнения таких договоров, в том числе является
                  совершеннолетним и полностью дееспособным лицом, либо
                  несовершеннолетним лицом, объявленным по решению
                  уполномоченного органа полностью дееспособным (эмансипация)
                  либо несовершеннолетним лицом, достигшим четырнадцати лет и
                  получившим письменное разрешение в требуемой законом форме от
                  своих родителей или иных законных представителей на заключение
                  договоров. Общество вправе в любое время потребовать от
                  Пользователя предоставление информации и документов,
                  подтверждающих права и полномочия, как указано выше.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  3. ПОРЯДОК И УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ СЕРВИСА
                </Typography>
                <Typography mt={1}>
                  3.1. Размещение информации об услугах осуществляется при
                  помощи программно-технических средств Сайта/Приложения через
                  Личный кабинет. Информация об услугах, и Исполнителе, должно
                  содержать в себе всю необходимую информацию, которая
                  предусмотрена требованиями действующего законодательства
                  Российской Федерации. Информация об услугах, и Исполнителе не
                  может содержать информацию, которая запрещена или ограничена в
                  обороте в соответствии с законодательством Российской
                  Федерации и Правилами.
                </Typography>
                <Typography mt={1}>
                  3.2. Условия оказания услуг, и заключаемой сделкой,
                  заключаемой между Заказчиком и Исполнителем, указываются
                  Исполнителем в отклике при отклике Исполнителем на задание от
                  Заказчика. До заключения Сделки с использованием Сервиса
                  Заказчик обязуется внимательно ознакомиться со всеми условиями
                  Сделки предлагаемой Исполнителем, указанными Исполнителем.
                  Заказчик вправе обратиться к Исполнителю с целью уточнения
                  условий оказания услуг, путем направления текстового сообщения
                  в Личном кабинете или иным способом. Выбор Исполнителя
                  означает согласие Заказчика со всеми условиями, указанными в
                  условиях оказания услуг.
                </Typography>
                <Typography mt={1}>
                  3.3. Для использования Сервиса Исполнитель обязан привязать к
                  его Личному кабинету Карту - для более удобной и быстрой
                  оплаты стоимости откликов на задания Заказчиков, для
                  осуществления возврата/частичного возврата денежных средств в
                  случаях, предусмотренных в настоящей Оферте. В случае привязки
                  Карты Банк осуществляет сохранение реквизитов (привязку) Карты
                  Пользователя, с целью предоставления возможности проведения
                  повторных операций с данной Картой в рамках Сервиса без
                  повторного ввода ее реквизитов.
                </Typography>
                <Typography mt={1}>
                  3.4. Списание денежных средств с аккаунта исполнителя в пользу
                  Общества, происходит автоматически после того, как Заказчик
                  выбрал данного Исполнителя. Списание происходит определённой
                  суммы, в зависимости от категории услуги, по заранее заданной
                  сервисом стоимости каждой категории услуг.{" "}
                </Typography>
                <Typography mt={1}>
                  3.5. При наличии у Исполнителя привязанной к его Личному
                  кабинету Карты либо при вводе данных Карты на соответствующей
                  Платежной странице Банка при оплате заказа, при условии, что
                  Банком получено разрешение на проведение операции по Карте в
                  результате Авторизации и/или Верификации, при наличии на счете
                  Карты денежной суммы, равной Стоимости категории услуги, а
                  также комиссии Банка, происходит списание денежных средств с
                  Карты Исполнителя и их зачисление на счёт Общества.
                </Typography>
                <Typography mt={1}>
                  3.6. Также Исполнитель вправе ввести данные своей Карты без ее
                  привязки.
                </Typography>
                <Typography mt={1}>
                  3.7. Заказчик и Исполнитель самостоятельно уплачивают все
                  необходимые налоги, сборы и взносы, подлежащие уплате в связи
                  с заключением Сделки, в соответствии с применимым правом и
                  самостоятельно несут соответствующие риски и ответственность в
                  случае их неуплаты.
                </Typography>
                <Typography mt={1}>
                  3.8 Заказчик и Исполнитель понимают и соглашаются, что при
                  переводах денежных средств на карты и с карту могут взиматься
                  дополнительные комиссии. Банк не несет ответственности за
                  комиссии, взимаемые банком-получателем и/или
                  банком-отправителем денежных средств.
                </Typography>
                <Typography mt={1}>
                  3.9 За оказание услуг в рамках Сервиса Банк имеет право
                  взимать с Исполнителя комиссию согласно Тарифам по платежам,
                  переводам и дополнительным услугам, размещенным по адресу
                  https://static.tinkoff.ru/documents/tariffs/acquiring.pdf.
                  Комиссия рассчитывается от суммы операции и включается в общую
                  сумму авторизационного запроса, проводимого по Карте, и
                  подлежит списанию без дополнительных распоряжений с Карты
                  отправителя сверх суммы операции в дату списания с Карты
                  отправителя суммы операции.{" "}
                </Typography>
                <Typography mt={1}>
                  3.10. При использовании Сервиса Пользователи обязуются:
                </Typography>
                <Typography mt={1}>
                  3.10.1. Надлежащим образом исполнять обязанности,
                  предусмотренные настоящей Офертой, а также обязательства по
                  Сделке, заключенной между Пользователями;
                </Typography>
                <Typography mt={1}>
                  3.10.2. Не использовать Сервис в целях осуществления
                  предпринимательской деятельности;
                </Typography>
                <Typography mt={1}>
                  3.10.3. При заключении Сделки проявлять осмотрительность;
                </Typography>
                <Typography mt={1}>
                  3.10.4. Не осуществлять реализацию в рамках Сделки товаров, не
                  указанных в Объявлении;
                </Typography>
                <Typography mt={1}>
                  3.11. Информационно-технологическое взаимодействие между
                  Банком и Заказчиком/ Исполнителем в части осуществления
                  переводов денежных средств за услуги обеспечивается Обществом
                  с использованием программных, аппаратных и технических средств
                  Сайта/Приложения. Банк при осуществлении переводов денежных
                  средств руководствуется Поручениями Покупателя, данными в
                  интерфейсе Сайта/Приложения.
                </Typography>
                <Typography mt={1}>
                  3.12. Стороны считают данные, полученные от Общества,
                  достаточным и надлежащим подтверждением тех или иных событий и
                  действий Сторон, связанных с выбором исполнителей, оказания
                  услуг в рамках Сервиса и исполнением обязательств
                  Пользователей в рамках Сервиса. Факт предоставления
                  Пользователю услуг в рамках Сервиса и их объем определяются на
                  основании статистических данных учетной системы Общества.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  4. ОБМЕН ИНФОРМАЦИЕЙ ПРИ ИСПОЛЬЗОВАНИИ СЕРВИСА
                </Typography>{" "}
                <Typography mt={1}>
                  4.1. В рамках Сервиса Общество и Банк могут направлять
                  Пользователям информационные сообщения, связанные с оказанием
                  услуг в рамках Сервиса. Сообщения Сервиса и Банка,
                  предназначенные для Пользователей, могут рассылаться
                  индивидуально по электронным адресам или на абонентские номера
                  Пользователей, предоставленные Пользователями при регистрации
                  Личного кабинета, размещении Объявлений на Сайте или подбор
                  Исполнителя. При этом Пользователь понимает, принимает и
                  соглашается, что рассылаемые сообщения и/или их отдельные
                  части могут иметь рекламный характер, а также могут содержать
                  рекламные, информационные и иные сообщения контрагентов
                  Сервиса. Сообщения, опубликованные на Сайте/Приложении,
                  считаются доставленными Пользователю с момента их публикации.
                  Общество может направлять Пользователям транзакционные и
                  сервисные сообщения, связанные с действиями Пользователей в
                  рамках Сервиса.
                </Typography>
                <Typography mt={1}>
                  4.2. Сообщения, обращения Пользователей, предусмотренные
                  настоящей Офертой, а также любые иные обращения Пользователей,
                  предназначенные для Сервиса, пересылаются способами,
                  предложенными на Сайте/Приложении, включая форму обратной
                  связи для обращений на Сайте/Приложении.
                </Typography>
                <Typography mt={1}>
                  4.3. Обращения Пользователя к Сервису по вопросам, связанным с
                  использованием Сервиса, рассматриваются в порядке,
                  предусмотренном настоящей Офертой. Взаимодействие Сервиса с
                  Пользователем в рамках рассмотрения обращения Пользователя
                  осуществляется с использованием указанного Пользователем
                  адреса электронной почты.
                </Typography>
                <Typography mt={1}>
                  4.4. Сообщения Пользователей друг другу могут быть направлены
                  посредством специально предложенной формы связи для
                  Пользователей в рамках Сервиса. Пользователь понимает и
                  соглашается с тем, что переписка с другими Пользователями в
                  рамках Сервиса, осуществляемая с помощью специально
                  предложенной формы связи, не является личной. Общество в любой
                  момент имеет право осуществлять просмотр отправляемых через
                  специально предложенную форму связи сообщений, включая, без
                  ограничений, в целях исполнения условий настоящей Оферты.
                  <br />
                  Общество не гарантирует, что на сообщение, направленное одним
                  Пользователем другому Пользователю, будет дан ответ, а также
                  не гарантирует доставку такого сообщения Пользователю.
                </Typography>
                <Typography mt={1}>
                  4.5. Порядок рассмотрения Обществом обращений Пользователей:
                </Typography>
                <Typography mt={1}>
                  4.5.1. В случае наличия претензий Пользователя в отношении
                  Сервиса или спора между Пользователями, связанного с
                  исполнением Пользователями своих обязательств по Сделке,
                  Пользователь вправе обратиться в Сервис с целью разрешения
                  указанных претензий и споров, направив соответствующее
                  сообщение в службу поддержки;
                </Typography>
                <Typography mt={1}>
                  4.5.2. Обращение должно содержать описание проблемы,
                  документы, подтверждающие позицию Пользователя, документы,
                  предусмотренные условиями настоящей Оферты, а также иные
                  необходимые документы. Сервис вправе запросить у Пользователя
                  иные документы и сведения, необходимые для рассмотрения
                  обращения (в т.ч., без ограничений, заключение независимой
                  экспертизы, если таковое необходимо для рассмотрения
                  обращения). В случае непредоставления Пользователем
                  запрошенных Обществом документов и сведений, Общество вправе
                  рассмотреть обращение Пользователя без учета таких документов
                  или приостановить рассмотрение обращения до момента
                  предоставления Пользователем необходимых документов или
                  сведений;
                </Typography>
                <Typography mt={1}>
                  4.5.3. В ходе рассмотрения обращения Общество вправе
                  обращаться к другой стороне Сделки с целью выяснения
                  обстоятельств спора между Пользователями и получения
                  необходимых документов или сведений. В случае рассмотрения
                  Сервисом спора между Пользователями, каждый из Пользователей
                  вправе предоставлять аргументы и доказательства надлежащего
                  исполнения своих обязательств по Сделке;
                </Typography>
                <Typography mt={1}>
                  4.5.4. По результатам рассмотрения обращения Пользователя
                  Общество принимает решение на основании имеющихся у Общества
                  документов и доводов;
                </Typography>
                <Typography mt={1}>
                  4.5.5. Пользователи принимают и соглашаются, что решение,
                  принятое Обществом по результатам рассмотрения обращения
                  Пользователей, может служить основанием для осуществления
                  расчетов между Пользователями в Сервисе (возврата денежных
                  средств Заказчику или перевода денежных средств Исполнителю).
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  5. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ
                </Typography>{" "}
                <Typography mt={1}>
                  5.1. Пользователь несет ответственность за действия,
                  совершаемые в рамках Общества, в соответствии с действующим
                  законодательством Российской Федерации и условиями настоящей
                  Оферты, включая ответственность за содержание размещаемой им
                  информации и нарушение прав третьих лиц в отношении Товаров и
                  услуг и/или информации, размещаемой на Сайте/приложении и пр.
                </Typography>
                <Typography mt={1}>
                  5.2. Пользователь несет ответственность за предлагаемые в
                  отношении услуг и заключаемые в связи с ними Сделки в рамках
                  Сервиса, за выбор контрагентов для сделки и вытекающие из
                  сделки последствия. Все Сделки в отношении Услуг заключаются
                  между Пользователями напрямую. Общество/Банк не являются
                  участниками и/или посредниками Сделок в рамках Сервиса,
                  совершаемых Пользователями исходя из информации, полученной на
                  Сайте/Приложении, не контролируют и не несут ответственности
                  за такие сделки.
                </Typography>
                <Typography mt={1}>
                  5.3. Сервис предназначен для использования в целях, не
                  связанных с осуществлением предпринимательской деятельности.
                  Отношения, являющиеся предметом Сделки, регулируются
                  законодательством Российской Федерации, при этом Пользователи
                  понимают, что отдельные положения законодательства Российской
                  Федерации, в т.ч. в части защиты прав потребителей, могут не
                  применяться к отношениям Пользователей, являющимся предметом
                  Сделки.
                </Typography>
                <Typography mt={1}>
                  5.4. Пользователь согласен, что Общество и/или Банк не несет
                  ответственности за возможные убытки, причиненные Пользователю
                  в связи с принятием мер пресечения или предотвращения
                  нарушений на Сайте, связанных с ограничением/блокировкой
                  доступа Пользователей к Сервису.
                </Typography>
                <Typography mt={1}>
                  5.5. Общество не несет ответственности за неисполнение или
                  затруднения в исполнении обязательств Пользователей, в т.ч.
                  обязательств по Сделке, вследствие обстоятельств непреодолимой
                  силы, последствия которых невозможно избежать или преодолеть.
                </Typography>
                <Typography mt={1}>
                  5.6. Общество или Банк не несет ответственность за
                  несвоевременное сообщение Заказчиком претензий к Исполнителю.
                </Typography>
                <Typography mt={1}>
                  5.7. Банк имеет право отказать Пользователю в оказании услуг
                  Банка в одностороннем порядке и без объяснения причин, в том
                  числе, если у Банка отсутствует техническая возможность для их
                  оказания, если параметры операции не соответствуют
                  установленным Банком и/или ПС и/или применимым
                  законодательством ограничениям по суммам и количеству операций
                  по Картам, в случае выявления операций Пользователей,
                  содержащих признаки необычных операций или несущих
                  репутационные и/или финансовые риски для Банка, а также, если
                  у Банка возникли подозрения в том, что операция осуществляется
                  с нарушением требований законодательства РФ и/или правил ПС.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  6. СРОК ДЕЙСТВИЯ ДОГОВОРА
                </Typography>{" "}
                <Typography mt={1}>
                  6.1. Заключаемые путем акцепта настоящей Оферты договоры
                  вступают в силу с момента акцепта условий настоящей Оферты
                  Пользователем в порядке, предусмотренном настоящей Офертой, и
                  действует до полного исполнения Сторонами своих обязательство
                  по нему. 6.2. Общество оставляет за собой право по
                  собственному усмотрению прекратить или ограничить доступ
                  Пользователя к Сервису.
                </Typography>
                <Typography mt={1}>
                  6.2. Общество оставляет за собой право по собственному
                  усмотрению прекратить или ограничить доступ Пользователя к
                  Сервису.
                </Typography>
                <Typography mt={1}>
                  6.3. Общество и/или Банк вправе изменять и/или дополнять
                  условия Оферты в той мере, в какой это допускается действующим
                  законодательством, а также отозвать Оферту в любое время.
                  Датой изменения Оферты является дата опубликования новой
                  редакции Оферты. Пользователь должен ознакомиться с
                  действующей редакцией Оферты, размещенной на Сайте/Приложении,
                  и с условиями каждой сделки до присоединения к Оферте и
                  совершения сделки.
                </Typography>
                <Typography mt={1}>
                  6.4. Заключение Пользователями Сделки с использованием Сервиса
                  после вступления в силу изменений в Оферте означает, что
                  стороны пришли к согласию о внесении соответствующих изменений
                  и/или дополнений в Оферте.{" "}
                </Typography>
                <Typography mt={1}>
                  6.5. Сервис вправе расторгнуть договоры в порядке,
                  предусмотренном действующим законодательством Российской
                  Федерации, с уведомлением об этом Пользователя через интерфейс
                  Сайта, по адресу электронной почты или иным способом. 6.6.
                  Действие договора об использовании Сервиса может быть
                  прекращено досрочно по инициативе Пользователя путем обращения
                  Пользователя в Компанию в порядке, предусмотренном настоящей
                  Офертой.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  7. СПОРЫ И ДЕЙСТВУЮЩЕЕ ЗАКОНОДАТЕЛЬСТВО
                </Typography>{" "}
                <Typography mt={1}>
                  7.1. При разрешении всех споров, вытекающих из настоящей
                  Оферты, применяется действующее законодательство Российской
                  Федерации.
                </Typography>
                <Typography mt={1}>
                  7.2. Споры, возникшие в рамках настоящего договора, должны
                  быть переданы на рассмотрение в соответствии с действующим
                  законодательством Российской Федерации.
                </Typography>
                <Typography mt={1}>
                  7.3. Признание отдельных частей настоящей Оферты
                  недействительными не отменяет действие других ее положений.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  8. ПРОЧИЕ УСЛОВИЯ
                </Typography>{" "}
                <Typography mt={1}>
                  8.1. Общество и/или Банк имеет право, а Пользователь
                  соглашается, на обработку любой информации, относящейся к
                  персональным и/или контактным данным Пользователя, с
                  использованием средств автоматизации или без таковых, включая
                  сбор, систематизацию, накопление, хранение, уточнение,
                  использование, распространение (в том числе передачу партнерам
                  Банка и/или Общества, трансграничную передачу), обезличивание,
                  блокирование, уничтожение персональных данных, предоставленных
                  Банку в связи с использованием Сервиса, и иные действия,
                  предусмотренные Федеральным законом от 27.07.2006 № 152-ФЗ «О
                  персональных данных». Пользователь выражает согласие Банку на
                  предоставление сведений, указанных в настоящем пункте,
                  Банку-эмитенту Карты получателя и/или ПС, для целей исполнения
                  условий настоящей Оферты, а также в любых других целях, прямо
                  или косвенно связанных с исполнением Банком обязательств в
                  рамках настоящей Оферты, и предложение продуктов Банка.
                </Typography>
                <Typography mt={1}>
                  8.2. Пользователь гарантирует, что все условия настоящей
                  Оферты ему понятны, и он принимает условия без оговорок и в
                  полном объеме.
                </Typography>
                <Typography mt={1}>
                  8.3. В случае, если отдельные положения настоящей Оферты
                  прекращают действие по основаниям, предусмотренным
                  законодательством Российской Федерации, это не влечет
                  прекращения или недействительности заключенных между сторонами
                  договоров и его приложений в целом.
                </Typography>
                <Typography mt={1}>
                  8.4. Все действия Пользователей, совершаемые в соответствии с
                  настоящей Офертой, обрабатываются и учитываются Банком и
                  Обществом по московскому времени. В качестве языка Договора,
                  заключаемого на условиях настоящей Оферты, а также языка,
                  используемого при любом взаимодействии сторон (включая ведение
                  переписки, предоставление требований/уведомлений/разъяснений,
                  предоставление документов и т.д.), стороны определили русский
                  язык.
                </Typography>
              </Box>
            </>
          ) : null}
          {status === "terms_of_use" ? (
            <>
              <Typography
                pt={2}
                pb={2}
                component="h1"
                variant="h4"
                sx={{
                  fontWeight: "500",
                }}
              >
                Политика конфинденциальности
              </Typography>
              <Box>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  1. Общие положения
                </Typography>
                <Typography>
                  Настоящая политика обработки персональных данных составлена в
                  соответствии с требованиями Федерального закона от 27.07.2006.
                  №152-ФЗ «О персональных данных» {"("}далее - Закон о
                  персональных данных{")"} и определяет порядок обработки
                  персональных данных и меры по обеспечению безопасности
                  персональных данных, предпринимаемые Лига Услуг {"("}далее –
                  Сервис{")"}.
                </Typography>
                <Typography mt={2}>
                  1.1. Сервис ставит своей важнейшей целью и условием
                  осуществления своей деятельности соблюдение прав и свобод
                  человека и гражданина при обработке его персональных данных, в
                  том числе защиты прав на неприкосновенность частной жизни,
                  личную и семейную тайну.
                </Typography>
                <Typography mt={1}>
                  1.2. Настоящая политика Сервиса в отношении обработки
                  персональных данных {"("}далее – Политика{")"} применяется ко
                  всей информации, которую Сервис может получить о посетителях
                  веб-сайта http://ligauslug.ru/
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  2. Основные понятия, используемые в Политике
                </Typography>
                <Typography mt={1}>
                  2.1. Автоматизированная обработка персональных данных –
                  обработка персональных данных с помощью средств вычислительной
                  техники.
                </Typography>
                <Typography mt={1}>
                  2.2. Блокирование персональных данных – временное прекращение
                  обработки персональных данных {"("}за исключением случаев,
                  если обработка необходима для уточнения персональных данных
                  {")"}.
                </Typography>
                <Typography mt={1}>
                  2.3. Веб-сайт – совокупность графических и информационных
                  материалов, а также программ для ЭВМ и баз данных,
                  обеспечивающих их доступность в сети интернет по сетевому
                  адресу http://ligauslug.ru/
                </Typography>
                <Typography mt={1}>
                  2.4. Информационная система персональных данных — совокупность
                  содержащихся в базах данных персональных данных, и
                  обеспечивающих их обработку информационных технологий и
                  технических средств.
                </Typography>
                <Typography mt={1}>
                  2.4. Информационная система персональных данных — совокупность
                  содержащихся в базах данных персональных данных, и
                  обеспечивающих их обработку информационных технологий и
                  технических средств.
                </Typography>
                <Typography mt={1}>
                  2.5. Обезличивание персональных данных — действия, в
                  результате которых невозможно определить без использования
                  дополнительной информации принадлежность персональных данных
                  конкретному Пользователю или иному субъекту персональных
                  данных.
                </Typography>
                <Typography mt={1}>
                  2.6. Обработка персональных данных – любое действие {"("}
                  операция{")"}
                  или совокупность действий {"("}операций{")"}, совершаемых с
                  использованием средств автоматизации или без использования
                  таких средств с персональными данными, включая сбор, запись,
                  систематизацию, накопление, хранение, уточнение {"("}
                  обновление, изменение{")"}, извлечение, использование,
                  передачу
                  {"("}распространение, предоставление, доступ{")"},
                  обезличивание, блокирование, удаление, уничтожение
                  персональных данных.
                </Typography>
                <Typography mt={1}>
                  2.7. Сервис Gorc-ka –юридическое лицо, самостоятельно или
                  совместно с другими лицами организующие и (или) осуществляющие
                  обработку персональных данных, а также определяющие цели
                  обработки персональных данных, состав персональных данных,
                  подлежащих обработке, действия (операции), совершаемые с
                  персональными данными.
                </Typography>
                <Typography mt={1}>
                  2.8. Персональные данные – любая информация, относящаяся прямо
                  или косвенно к определенному или определяемому Пользователю
                  веб-сайта http://ligauslug.ru/
                </Typography>
                <Typography mt={1}>
                  2.9. Персональные данные, разрешенные субъектом персональных
                  данных для распространения, - персональные данные, доступ
                  неограниченного круга лиц к которым предоставлен субъектом
                  персональных данных путем дачи согласия на обработку
                  персональных данных, разрешенных субъектом персональных данных
                  для распространения в порядке, предусмотренном Законом о
                  персональных данных (далее - персональные данные, разрешенные
                  для распространения).
                </Typography>
                <Typography mt={1}>
                  2.10. Пользователь – любой посетитель
                  веб-сайта http://ligauslug.ru/
                </Typography>
                <Typography mt={1}>
                  2.11. Предоставление персональных данных – действия,
                  направленные на раскрытие персональных данных определенному
                  лицу или определенному кругу лиц.
                </Typography>
                <Typography mt={1}>
                  2.12. Распространение персональных данных – любые действия,
                  направленные на раскрытие персональных данных неопределенному
                  кругу лиц (передача персональных данных) или на ознакомление с
                  персональными данными неограниченного круга лиц, в том числе
                  обнародование персональных данных в средствах массовой
                  информации, размещение в информационно-телекоммуникационных
                  сетях или предоставление доступа к персональным данным
                  каким-либо иным способом.
                </Typography>
                <Typography mt={1}>
                  2.13. Трансграничная передача персональных данных – передача
                  персональных данных на территорию иностранного государства
                  органу власти иностранного государства, иностранному
                  физическому или иностранному юридическому лицу.
                </Typography>
                <Typography mt={1}>
                  2.14. Уничтожение персональных данных – любые действия, в
                  результате которых персональные данные уничтожаются
                  безвозвратно с невозможностью дальнейшего восстановления
                  содержания персональных данных в информационной системе
                  персональных данных и (или) уничтожаются материальные носители
                  персональных данных.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  3. Основные права и обязанности Оператора
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      display: "block",
                    },
                  }}
                >
                  3.1. Сервис Gorc-ka имеет право:
                  <span>
                    {" "}
                    – получать от субъекта персональных данных достоверные
                    информацию и/или документы, содержащие персональные данные;
                  </span>
                  <span>
                    {" "}
                    – в случае отзыва субъектом персональных данных согласия на
                    обработку персональных данных Сервис вправе продолжить
                    обработку персональных данных без согласия субъекта
                    персональных данных при наличии оснований, указанных в
                    Законе о персональных данных; – самостоятельно определять
                    состав и перечень мер, необходимых и достаточных для
                    обеспечения выполнения обязанностей, предусмотренных Законом
                    о персональных данных и принятыми в соответствии с ним
                    нормативными правовыми актами, если иное не предусмотрено
                    Законом о персональных данных или другими федеральными
                    законами.
                  </span>
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      display: "block",
                    },
                  }}
                >
                  3.2. Сервис обязан:
                  <span>
                    {" "}
                    – предоставлять субъекту персональных данных по его просьбе
                    информацию, касающуюся обработки его персональных данных;
                  </span>{" "}
                  <span>
                    {" "}
                    – организовывать обработку персональных данных в порядке,
                    установленном действующим законодательством РФ;
                  </span>{" "}
                  <span>
                    {" "}
                    – отвечать на обращения и запросы субъектов персональных
                    данных и их законных представителей в соответствии с
                    требованиями Закона о персональных данных; – сообщать в
                    уполномоченный орган по защите прав субъектов персональных
                    данных по запросу этого органа необходимую информацию в
                    течение 30 дней с даты получения такого запроса;
                  </span>{" "}
                  <span>
                    {" "}
                    – публиковать или иным образом обеспечивать неограниченный
                    доступ к настоящей Политике в отношении обработки
                    персональных данных;
                  </span>
                  <span>
                    {" "}
                    – принимать правовые, организационные и технические меры для
                    защиты персональных данных от неправомерного или случайного
                    доступа к ним, уничтожения, изменения, блокирования,
                    копирования, предоставления, распространения персональных
                    данных, а также от иных неправомерных действий в отношении
                    персональных данных;
                  </span>
                  <span>
                    {" "}
                    – прекратить передачу (распространение, предоставление,
                    доступ) персональных данных, прекратить обработку и
                    уничтожить персональные данные в порядке и случаях,
                    предусмотренных Законом о персональных данных; – исполнять
                    иные обязанности, предусмотренные Законом о персональных
                    данных.
                  </span>
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  4. Основные права и обязанности субъектов персональных данных
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    "& span": {
                      display: "block",
                    },
                  }}
                >
                  4.1. Субъекты персональных данных имеют право:{" "}
                  <span>
                    – получать информацию, касающуюся обработки его персональных
                    данных, за исключением случаев, предусмотренных федеральными
                    законами. Сведения предоставляются субъекту персональных
                    данных Оператором в доступной форме, и в них не должны
                    содержаться персональные данные, относящиеся к другим
                    субъектам персональных данных, за исключением случаев, когда
                    имеются законные основания для раскрытия таких персональных
                    данных. Перечень информации и порядок ее получения
                    установлен Законом о персональных данных;
                  </span>{" "}
                  <span>
                    {" "}
                    – требовать от Сервиса уточнения его персональных данных, их
                    блокирования или уничтожения в случае, если персональные
                    данные являются неполными, устаревшими, неточными, незаконно
                    полученными или не являются необходимыми для заявленной цели
                    обработки, а также принимать предусмотренные законом меры по
                    защите своих прав;
                  </span>{" "}
                  <span>
                    {" "}
                    – выдвигать условие предварительного согласия при обработке
                    персональных данных в целях продвижения на рынке товаров,
                    работ и услуг;
                  </span>{" "}
                  <span>
                    {" "}
                    – на отзыв согласия на обработку персональных данных;
                  </span>{" "}
                  <span>
                    {" "}
                    – обжаловать в уполномоченный орган по защите прав субъектов
                    персональных данных или в судебном порядке неправомерные
                    действия или бездействие Оператора при обработке его
                    персональных данных;
                  </span>{" "}
                  <span>
                    {" "}
                    – на осуществление иных прав, предусмотренных
                    законодательством РФ.
                  </span>
                </Typography>
                <Typography mt={1}>
                  4.2. Субъекты персональных данных обязаны:
                  <br /> – предоставлять Сервису достоверные данные о себе;
                  <br /> – сообщать Сервису об уточнении (обновлении, изменении)
                  своих персональных данных.
                </Typography>
                <Typography mt={1}>
                  4.3. Лица, передавшие Сервису недостоверные сведения о себе,
                  либо сведения о другом субъекте персональных данных без
                  согласия последнего, несут ответственность в соответствии с
                  законодательством РФ.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  5. Сервис может обрабатывать следующие персональные данные
                  Пользователя
                </Typography>
                <Typography mt={1}>5.1. Фамилия, имя, отчество.</Typography>
                <Typography mt={1}>5.2. Электронный адрес.</Typography>
                <Typography mt={1}></Typography>
                <Typography mt={1}>5.3. Номера телефонов.</Typography>
                <Typography mt={1}>5.4 Город проживания.</Typography>
                <Typography mt={1}>5.5 Фото.</Typography>
                <Typography mt={1}>
                  5.4. Также на сайте происходит сбор и обработка обезличенных
                  данных о посетителях (в т.ч. файлов «cookie») с помощью
                  сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика
                  и других).
                </Typography>
                <Typography mt={1}>
                  5.5. Вышеперечисленные данные далее по тексту Политики
                  объединены общим понятием Персональные данные.
                </Typography>
                <Typography mt={1}>
                  5.6. Обработка специальных категорий персональных данных,
                  касающихся расовой, национальной принадлежности, политических
                  взглядов, религиозных или философских убеждений, интимной
                  жизни, Сервисом не осуществляется.
                </Typography>
                <Typography mt={1}>
                  5.7. Обработка персональных данных, разрешенных для
                  распространения, из числа специальных категорий персональных
                  данных, указанных в ч. 1 ст. 10 Закона о персональных данных,
                  допускается, если соблюдаются запреты и условия,
                  предусмотренные ст. 10.1 Закона о персональных данных.
                </Typography>
                <Typography mt={1}>
                  5.8. Согласие Пользователя на обработку персональных данных,
                  разрешенных для распространения, оформляется отдельно от
                  других согласий на обработку его персональных данных. При этом
                  соблюдаются условия, предусмотренные, в частности, ст. 10.1
                  Закона о персональных данных. Требования к содержанию такого
                  согласия устанавливаются уполномоченным органом по защите прав
                  субъектов персональных данных.
                </Typography>
                <Typography mt={1}>
                  5.8.1 Согласие на обработку персональных данных, разрешенных
                  для распространения, Пользователь предоставляет Сервису
                  непосредственно. 5.8.2 Сервис обязан в срок не позднее трех
                  рабочих дней с момента получения указанного согласия
                  Пользователя опубликовать информацию об условиях обработки, о
                  наличии запретов и условий на обработку неограниченным кругом
                  лиц персональных данных, разрешенных для распространения.
                </Typography>
                <Typography mt={1}>
                  5.8.3 Передача (распространение, предоставление, доступ)
                  персональных данных, разрешенных субъектом персональных данных
                  для распространения, должна быть прекращена в любое время по
                  требованию субъекта персональных данных. Данное требование
                  должно включать в себя фамилию, имя, отчество (при наличии),
                  контактную информацию (номер телефона, адрес электронной почты
                  или почтовый адрес) субъекта персональных данных, а также
                  перечень персональных данных, обработка которых подлежит
                  прекращению. Указанные в данном требовании персональные данные
                  могут обрабатываться только Сервисом, которому оно направлено.
                </Typography>
                <Typography mt={1}>
                  5.8.4 Согласие на обработку персональных данных, разрешенных
                  для распространения, прекращает свое действие с момента
                  поступления Сервису требования, указанного в п. 5.8.3
                  настоящей Политики в отношении обработки персональных данных.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  6. Принципы обработки персональных данных
                </Typography>
                <Typography mt={1}>
                  6.1. Обработка персональных данных осуществляется на законной
                  и справедливой основе.
                </Typography>
                <Typography mt={1}>
                  6.2. Обработка персональных данных ограничивается достижением
                  конкретных, заранее определенных и законных целей. Не
                  допускается обработка персональных данных, несовместимая с
                  целями сбора персональных данных.
                </Typography>
                <Typography mt={1}>
                  6.3. Не допускается объединение баз данных, содержащих
                  персональные данные, обработка которых осуществляется в целях,
                  несовместимых между собой.
                </Typography>
                <Typography mt={1}>
                  6.4. Обработке подлежат только персональные данные, которые
                  отвечают целям их обработки.
                </Typography>
                <Typography mt={1}>
                  6.5. Содержание и объем обрабатываемых персональных данных
                  соответствуют заявленным целям обработки. Не допускается
                  избыточность обрабатываемых персональных данных по отношению к
                  заявленным целям их обработки.
                </Typography>
                <Typography mt={1}>
                  6.6. При обработке персональных данных обеспечивается точность
                  персональных данных, их достаточность, а в необходимых случаях
                  и актуальность по отношению к целям обработки персональных
                  данных. Сервис принимает необходимые меры и/или обеспечивает
                  их принятие по удалению или уточнению неполных или неточных
                  данных.
                </Typography>
                <Typography mt={1}>
                  6.7. Хранение персональных данных осуществляется в форме,
                  позволяющей определить субъекта персональных данных, не
                  дольше, чем этого требуют цели обработки персональных данных,
                  если срок хранения персональных данных не установлен
                  федеральным законом, договором, стороной которого,
                  выгодоприобретателем или поручителем по которому является
                  субъект персональных данных. Обрабатываемые персональные
                  данные уничтожаются либо обезличиваются по достижении целей
                  обработки или в случае утраты необходимости в достижении этих
                  целей, если иное не предусмотрено федеральным законом.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  7. Цели обработки персональных данных
                </Typography>
                <Typography mt={1}>
                  7.1. Цель обработки персональных данных Пользователя:
                  <br />
                  – информирование Пользователя посредством отправки электронных
                  писем;
                  <br />
                  – заключение, исполнение и прекращение гражданско-правовых
                  договоров;
                  <br />
                  – предоставление доступа Пользователю к сервисам, информации
                  и/или материалам, содержащимся на
                  веб-сайте http://ligauslug.ru/
                </Typography>
                <Typography mt={1}>
                  7.2. Также Сервис имеет право направлять Пользователю
                  уведомления о новых продуктах и услугах, специальных
                  предложениях и различных событиях. Пользователь всегда может
                  отказаться от получения информационных сообщений, направив
                  Оператору письмо на адрес электронной почты liga124@mail.ru с
                  пометкой «Отказ от уведомлений о новых продуктах и услугах и
                  специальных предложениях».
                </Typography>
                <Typography mt={1}>
                  7.3. Обезличенные данные Пользователей, собираемые с помощью
                  сервисов интернет-статистики, служат для сбора информации о
                  действиях Пользователей на сайте, улучшения качества сайта и
                  его содержания.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  8. Правовые основания обработки персональных данных
                </Typography>{" "}
                <Typography mt={1}>
                  8.1. Правовыми основаниями обработки персональных данных
                  Сервиса являются:
                  <br />
                  – Федеральный закон "Об информации, информационных технологиях
                  и о защите информации" от 27.07.2006 N 149-ФЗ;
                  <br />
                  – уставные документы Сервиса;
                  <br />
                  – договоры, заключаемые между Сервисом и субъектом
                  персональных данных;
                  <br />– федеральные законы, иные нормативно-правовые акты в
                  сфере защиты персональных данных;
                  <br />– согласия Пользователей на обработку их персональных
                  данных, на обработку персональных данных, разрешенных для
                  распространения.
                </Typography>
                <Typography mt={1}>
                  8.2. Сервис обрабатывает персональные данные Пользователя
                  только в случае их заполнения и/или отправки Пользователем
                  самостоятельно через специальные формы, расположенные на
                  сайте http://ligauslug.ru/ или направленные Сервису
                  посредством электронной почты. Заполняя соответствующие формы
                  и/или отправляя свои персональные данные Сервису, Пользователь
                  выражает свое согласие с данной Политикой.
                </Typography>
                <Typography mt={1}>
                  8.3. Сервис обрабатывает обезличенные данные о Пользователе в
                  случае, если это разрешено в настройках браузера Пользователя
                  (включено сохранение файлов «cookie» и использование
                  технологии JavaScript).
                </Typography>
                <Typography mt={1}>
                  8.4. Субъект персональных данных самостоятельно принимает
                  решение о предоставлении его персональных данных и дает
                  согласие свободно, своей волей и в своем интересе.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  9. Условия обработки персональных данных
                </Typography>{" "}
                <Typography mt={1}>
                  9.1. Обработка персональных данных осуществляется с согласия
                  субъекта персональных данных на обработку его персональных
                  данных.
                </Typography>
                <Typography mt={1}>
                  9.2. Обработка персональных данных необходима для достижения
                  целей, предусмотренных международным договором Российской
                  Федерации или законом, для осуществления возложенных
                  законодательством Российской Федерации на оператора функций,
                  полномочий и обязанностей.
                </Typography>
                <Typography mt={1}>
                  9.3. Обработка персональных данных необходима для
                  осуществления правосудия, исполнения судебного акта, акта
                  другого органа или должностного лица, подлежащих исполнению в
                  соответствии с законодательством Российской Федерации об
                  исполнительном производстве.
                </Typography>
                <Typography mt={1}>
                  9.4. Обработка персональных данных необходима для исполнения
                  договора, стороной которого либо выгодоприобретателем или
                  поручителем по которому является субъект персональных данных,
                  а также для заключения договора по инициативе субъекта
                  персональных данных или договора, по которому субъект
                  персональных данных будет являться выгодоприобретателем или
                  поручителем.
                </Typography>
                <Typography mt={1}>
                  9.5. Обработка персональных данных необходима для
                  осуществления прав и законных интересов Сервиса или третьих
                  лиц либо для достижения общественно значимых целей при
                  условии, что при этом не нарушаются права и свободы субъекта
                  персональных данных.
                </Typography>
                <Typography mt={1}>
                  9.6. Осуществляется обработка персональных данных, доступ
                  неограниченного круга лиц к которым предоставлен субъектом
                  персональных данных либо по его просьбе (далее – общедоступные
                  персональные данные).
                </Typography>
                <Typography mt={1}>
                  9.7. Осуществляется обработка персональных данных, подлежащих
                  опубликованию или обязательному раскрытию в соответствии с
                  федеральным законом.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  10. Порядок сбора, хранения, передачи и других видов обработки
                  персональных данных
                </Typography>{" "}
                <Typography mt={1}>
                  Безопасность персональных данных, которые обрабатываются
                  Сервисом, обеспечивается путем реализации правовых,
                  организационных и технических мер, необходимых для выполнения
                  в полном объеме требований действующего законодательства в
                  области защиты персональных данных.
                </Typography>
                <Typography mt={1}>
                  10.1. Сервис обеспечивает сохранность персональных данных и
                  принимает все возможные меры, исключающие доступ к
                  персональным данным неуполномоченных лиц.
                </Typography>
                <Typography mt={1}>
                  10.2. Персональные данные Пользователя никогда, ни при каких
                  условиях не будут переданы третьим лицам, за исключением
                  случаев, связанных с исполнением действующего законодательства
                  либо в случае, если субъектом персональных данных дано
                  согласие Сервису на передачу данных третьему лицу для
                  исполнения обязательств по гражданско-правовому договору.
                </Typography>
                <Typography mt={1}>
                  10.3. В случае выявления неточностей в персональных данных,
                  Пользователь может актуализировать их самостоятельно, путем
                  направления Сервису уведомление на адрес электронной почты
                  Сервиса liga124@mail.ru с пометкой «Актуализация персональных
                  данных».
                </Typography>
                <Typography mt={1}>
                  10.4. Срок обработки персональных данных определяется
                  достижением целей, для которых были собраны персональные
                  данные, если иной срок не предусмотрен договором или
                  действующим законодательством.Пользователь может в любой
                  момент отозвать свое согласие на обработку персональных
                  данных, направив Сервису уведомление посредством электронной
                  почты на электронный адрес Оператора liga124@mail.ru с
                  пометкой «Отзыв согласия на обработку персональных данных».
                </Typography>
                <Typography mt={1}>
                  10.5. Вся информация, которая собирается сторонними сервисами,
                  в том числе платежными системами, средствами связи и другими
                  поставщиками услуг, хранится и обрабатывается указанными
                  лицами (Операторами) в соответствии с их Пользовательским
                  соглашением и Политикой конфиденциальности. Субъект
                  персональных данных и/или Пользователь обязан самостоятельно
                  своевременно ознакомиться с указанными документами. Сервис не
                  несет ответственность за действия третьих лиц, в том числе
                  указанных в настоящем пункте поставщиков услуг.
                </Typography>
                <Typography mt={1}>
                  10.6. Установленные субъектом персональных данных запреты на
                  передачу (кроме предоставления доступа), а также на обработку
                  или условия обработки (кроме получения доступа) персональных
                  данных, разрешенных для распространения, не действуют в
                  случаях обработки персональных данных в государственных,
                  общественных и иных публичных интересах, определенных
                  законодательством РФ.
                </Typography>
                <Typography mt={1}>
                  10.7. Сервис при обработке персональных данных обеспечивает
                  конфиденциальность персональных данных.
                </Typography>
                <Typography mt={1}>
                  10.8. Сервис осуществляет хранение персональных данных в
                  форме, позволяющей определить субъекта персональных данных, не
                  дольше, чем этого требуют цели обработки персональных данных,
                  если срок хранения персональных данных не установлен
                  федеральным законом, договором, стороной которого,
                  выгодоприобретателем или поручителем по которому является
                  субъект персональных данных.
                </Typography>
                <Typography mt={1}>
                  10.9. Условием прекращения обработки персональных данных может
                  являться достижение целей обработки персональных данных,
                  истечение срока действия согласия субъекта персональных данных
                  или отзыв согласия субъектом персональных данных, а также
                  выявление неправомерной обработки персональных данных.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  11. Перечень действий, производимых Сервисом с полученными
                  персональными данными
                </Typography>{" "}
                <Typography mt={1}>
                  11.1. Сервис осуществляет сбор, запись, систематизацию,
                  накопление, хранение, уточнение (обновление, изменение),
                  извлечение, использование, передачу (распространение,
                  предоставление, доступ), обезличивание, блокирование, удаление
                  и уничтожение персональных данных.
                </Typography>
                <Typography mt={1}>
                  11.2. Сервис осуществляет автоматизированную обработку
                  персональных данных с получением и/или передачей полученной
                  информации по информационно-телекоммуникационным сетям или без
                  таковой.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  12. Конфиденциальность персональных данных
                </Typography>{" "}
                <Typography mt={1}>
                  Оператор и иные лица, получившие доступ к персональным данным,
                  обязаны не раскрывать третьим лицам и не распространять
                  персональные данные без согласия субъекта персональных данных,
                  если иное не предусмотрено федеральным законом.
                </Typography>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  13. Заключительные положения
                </Typography>{" "}
                <Typography mt={1}>
                  13.1. Пользователь может получить любые разъяснения по
                  интересующим вопросам, касающимся обработки его персональных
                  данных, обратившись в Сервис с помощью электронной
                  почты liga124@mail.ru
                </Typography>
                <Typography mt={1}>
                  13.2. В данном документе будут отражены любые изменения
                  политики обработки персональных данных Оператором. Политика
                  действует бессрочно до замены ее новой версией.
                </Typography>
                <Box
                  style={{ display: "flex", justifyContent: "center" }}
                  mb={5}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Button color="success" variant="contained">
                      Принимаю
                    </Button>
                  </Link>
                </Box>
              </Box>
            </>
          ) : null}
          {status === "privacy_policy" ? (
            <>
              <Typography
                pt={2}
                pb={2}
                component="h1"
                variant="h4"
                sx={{
                  fontWeight: "500",
                }}
              >
                Условия использования сервиса «Лига Услуг»
              </Typography>
              <Box>
                <Typography
                  pt={2}
                  pb={2}
                  component="h2"
                  variant="h5"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  1. Общие условия
                </Typography>
                <Typography mt={2}>
                  1.1. В целях настоящего документа нижеприведенные термины
                  используются в следующем значении:
                </Typography>
                <Typography pl={4}>
                  Пользователь – пользователь сети «Интернет», использующий
                  функциональные возможности Сервиса и его отдельных функций;
                </Typography>
                <Typography pl={4}>
                  Сервис услуг – сервис Gorc-ka, доступный по адресу (
                  вставить адрес ) и на иных ресурсах, Условия – настоящие
                  Условия использования сервиса Gorc-ka опубликованные по
                  адресу ( вставить адрес )
                </Typography>
                <Typography pl={4}>
                  Исполнитель – пользователь, размещающий в сервисе объявление о
                  своих услугах, либо осуществляющий отклики на заказы;
                </Typography>
                <Typography pl={4}>
                  Услуга – услуга, работа, купля-продажа и доставка товара, в
                  отношении которых пользователь разместил объявление или заказ,
                  либо осуществил отклик.
                </Typography>
                <Typography pl={4}>
                  Партнер – лицо, вступившее с Gorc-ka в договорные
                  отношения, касающиеся использования сервиса в коммерческой
                  деятельности партнера с целью привлечения клиентов, а также в
                  иных целях.
                </Typography>
                <Typography pl={4}>
                  Заявка – заявка клиента ( пользователя ) о необходимости
                  оказать услугу на указанных в нем условиях. Заявка может быть
                  опубликована на странице Gorc-ka и доступна неопределенному
                  кругу лиц кто указал данное направление услуг в своих
                  настройках, либо может быть направлен посредством
                  функциональных возможностей сервиса в отношении конкретного
                  предложения оказания услуг.
                </Typography>
                <Typography pl={4}>
                  Клиент – пользователь сервиса, размещающий в сервисе заявки на
                  оказание услуг, или поиск специалиста в определённом
                  направлении;
                </Typography>
                <Typography pl={4}>
                  Отклик – сообщение клиенту от специалиста с информацией о
                  сроках, стоимости исполнения данного задания
                </Typography>
                <Typography pl={4}>
                  Аккаунт – учетная запись пользователя Gorc-ka;
                </Typography>
                <Typography pl={4}>
                  Публичная страница – страница исполнителя, доступная в Лига
                  услуг для неограниченного круга лиц, содержащая информацию об
                  исполнителе, в том числе его контактные данные, информацию об
                  оказываемых им услугах, опыте работы, специальных отметках
                  сервиса, оценках, рейтинге на сервисе, личное фото, город
                  проживания, и т.д;
                </Typography>
                <Typography pl={4}>
                  Контент – любые текстовые, фото, видео-материалы, загружаемые
                  пользователем в сервис или автоматически переносимые им в
                  сервис.
                </Typography>
                <Typography mt={2}>
                  1.2. Gorc-ka предлагает любому пользователю использовать
                  данную интернет площадку, на настоящих Условиях .
                </Typography>
                <Typography mt={2}>
                  1.3. Использование Данной интернет площадки регулируется
                  настоящими условиями.
                </Typography>
                <Typography mt={2}>
                  1.4. Начиная использовать Gorc-ka и его отдельные функции,
                  пользователь считается принявшим настоящие условия, в полном
                  объеме, без всяких оговорок и исключений. В случае несогласия
                  пользователя с какими-либо из положений указанных документов,
                  пользователь в праве не использовать Gorc-ka.
                </Typography>
                <Typography mt={2}>
                  1.5. Настоящие условия, могут быть изменены без уведомления.
                  Новые условия вступают в силу с момента их размещения в сети
                  интернет. Продолжение пользования Gorc-ka после изменения
                  условий считается согласием с их новыми изменениями.
                </Typography>
                <Typography mt={2}>
                  1.6. Используя Gorc-ka, пользователь дает свое согласие на
                  получение сообщений информационного или рекламного характера.
                </Typography>
                <Typography mt={2}>
                  1.7. Gorc-ka предоставляет клиентам, возможность
                  размещения, поиска и просмотра объявлений и поиска
                  исполнителей, а исполнителям откликов на заказы, и также
                  другие возможности, для всех пользователей Gorc-ka.
                </Typography>
                <Typography mt={2}>
                  1.8. Функционал сервиса может быть доступен пользователю после
                  регистрации на сервисе Gorc-ka, согласно условиям.
                </Typography>
                <Typography mt={2}>
                  1.9. Пользователь, после авторизации, имеет право создать
                  аккаунта в Gorc-ka, после чего может создавать объявления,
                  размещать задания на услугу, осуществлять отклики на заявки,
                  добавлять информацию в профиль, редактировать свой профиль.
                </Typography>
                <Typography>
                  Нельзя создавать несколько аккаунтов. В случае выявления у
                  пользователя дополнительного аккаунта, данный пользователь
                  может быть заблокирован навсегда в сервисе Gorc-ka, без
                  предупреждения, или аннулирован.
                </Typography>
                <Typography mt={2}>
                  1.10. Gorc-ka предоставляет зарегистрированному
                  пользователю возможность писать сообщения другому пользователю
                  данной интернет площадки, возможность проводить обсуждения
                  исполнителей между исполнителями, и так же клиентов между
                  клиентами.
                </Typography>
                <Typography>
                  В целях осуществления контроля качества, в целях проверки
                  факта и условий оказания услуг клиенту исполнителем, в рамках
                  выполнения требований законодательства, Gorc-ka хранит
                  историю взаимодействия между клиентом и исполнителем.
                </Typography>
                <Typography mt={2}>
                  1.11. Клиент имеет возможность публиковать отзывы, и ставить
                  баллы исполнителю. Отзыв должен выражать оценку клиента
                  относительно оказанных ему услуг или любого взаимодействия с
                  исполнителем, отзыв и оценка должны содержать достоверно
                  изложенные фактические обстоятельства опыта обращения к
                  исполнителю, без каких либо оскорблений. Отзыв клиент, может
                  опубликовать, только от своего имен.
                </Typography>
                <Typography mt={2}>
                  1.12. Gorc-ka может осуществлять проверку благонадежности
                  исполнителя основываясь на данных, предоставленных
                  исполнителем при создании аккаунта в Gorc-ka. Проверка
                  может подразумевать использование открытых источников и баз
                  данных, а также привлечение третьих лиц.
                </Typography>
                <Typography>
                  По результатам проведенной проверки благонадежности
                  исполнителя, а также в случае отказа исполнителя от
                  предоставления документов, Gorc-ka может отказать
                  исполнителю в предоставлении доступа к сайту Gorc-ka, без
                  предоставления пояснений причин отказа.
                </Typography>
                <Typography mt={2}>
                  1.13. Пополняя личный счет исполнителя в Gorc-ka,
                  поступившая денежная сумма, направляется на личный счет
                  исполнителя в Gorc-ka и может быть использована для
                  возможности выполнять отклики на задания от клиентов, или
                  возвращена по запросу исполнителя, равной фактической суммы на
                  момент запроса на возврат денежных средств.
                </Typography>
                <Typography>
                  Gorc-ka имеет возможность оценивать активность исполнителя,
                  исходя из установленных сайтом критериев, таких как: частота
                  откликов, подтверждение договоренности с заказчиком об услуге,
                  подтверждение и закрытие выполненных заказов, скорость ответа
                  клиентам, рейтинг исполнителя, определяемый на основании
                  отзывов и оценок от клиентов, и другое.
                </Typography>
                <Typography>
                  Оценки и рейтинг исполнителя доступен в личном кабинете
                  исполнителя. Его видят все пользователи сайта.
                </Typography>
                <Typography>
                  Так же у администраторов сайта будет возможность временно
                  ставить в бан на некоторое время исполнителей.
                </Typography>
                <Typography mt={2}>
                  1.14. Gorc-ka предоставляет исполнителям откликаться на
                  заявки, приходящие от клиентов. Когда исполнитель выполнил
                  отклик и предложил условия выполнения заказа, клиенту приходит
                  предложение вступить в переговоры по поводу оказания услуги на
                  таких то условиях, за такую то стоимость. Заказчик, изучив все
                  предложения, сам принимает решение какого специалиста выбрать
                  в роли исполнителя.
                </Typography>
                <Typography>
                  Исполнителю запрещается при выполнении откликов,
                  злоупотреблять функциональными возможностями сервиса Лига
                  услуг, использование каких-либо программ, ботов, позволяющих
                  размещать отклики в автоматическом режиме.
                </Typography>
                <Typography>
                  Аккаунт исполнителя может содержать информацию о количестве
                  выполненных заказов с использованием сервиса Gorc-ka.
                </Typography>
                <Typography mt={2}>
                  1.15. Размещая карточку специалиста в сервисе Gorc-ka,
                  исполнитель соглашается с тем, что предоставленные им
                  сведения, включая контактные данные, личное фото, контент
                  будут доступны неограниченному кругу лиц на территории всего
                  мира.
                </Typography>
                <Typography>
                  Gorc-ka не может гарантировать, что данные сведения могут
                  быть использованы третьими лицами, а также не несет
                  ответственности за действия третьих лиц по копированию и
                  обработке предоставленной пользователем информации в целях, не
                  связанных с использованием Сервиса. Размещая контактные
                  данные, пользователь соглашается на запись сервисом Gorc-ka
                  разговора и номера телефона, с которого совершен звонок.
                  Записи звонков могут быть использованы в целях контроля
                  качества работы сервиса Gorc-ka, рассмотрения жалоб
                  пользователей и контроля качества оказания услуг
                  специалистами.
                </Typography>
                <Typography mt={2}>
                  1.16. Исполнитель создавая карточку специалиста в сервисе Лига
                  услуг, обязуется осуществлять отклики только в отношении
                  услуг, оказываемых им лично, нельзя выдавать себя за другое
                  лицо, перепоручать задания от клиентов, перепродавать,
                  производить обмен заказов, полученных или взятых в сервисе
                  Gorc-ka, как на возмездной, так и на безвозмездной основе.
                </Typography>
                <Typography mt={2}>
                  1.17.Пользователь самостоятельно и всецело несёт все риски и
                  ответственность за соответствие законодательству, содержание,
                  полноту, корректность и достоверность размещенной им
                  информации в сервисе Gorc-ka.
                </Typography>
                <Typography>
                  Запрещено использование сайта Gorc-ka и его функций с целью
                  загрузки, размещения, передачи, распространения не разрешенной
                  специальным образом рекламной информации, различной рассылки,
                  спама, материалов, содержащих вирусы или другие вредоносные
                  компьютерные коды, файлы или программы, использовать сайт
                  исключительно для перенаправления пользователей на страницы
                  других сайтов.
                </Typography>
                <Typography>
                  Gorc-ka оставляет за собой право без предупреждения и по
                  своему усмотрению удалить любое объявление, информацию,
                  опубликованную на сайте, или ограничить доступ пользователя к
                  сайту в том числе, в случае, если действия пользователя,
                  размещение контента или иной информации противоречит
                  законодательству Российской Федерации, настоящим условиям, а
                  также требованиям сайта к публикуемым материалам.
                </Typography>
                <Typography mt={2}>
                  1.18. Gorc-ka предоставляет всем пользователям бесплатную
                  возможность размещения заказов на поиск специалиста в каком
                  либо направлении.
                </Typography>
                <Typography>
                  Клиент настоящим уведомлен и соглашается, что в случае
                  оформления заявки на поиск специалиста через платформу Лига
                  услуг, вся предоставленная им информация может быть передана
                  привлекаемым исполнителям, назначенным исполнителем для
                  оказания услуги, в целях обратной связи по вопросам оказания
                  услуг и фактического выполнения заявки.
                </Typography>
                <Typography>
                  Услуги клиенту оказывает исполнитель с сайта Gorc-ka, но
                  при этом Gorc-ka не является участником отношений по
                  оказанию исполнителем услуг клиенту, и не может нести
                  ответственность за качество, своевременность, полноту
                  оказанных услуг, а также за действия исполнителя.
                </Typography>
                <Typography>
                  Gorc-ka рекомендует всем клиентам и кто ищет специалистов
                  через Gorc-ka, после выбора специалиста, не вносить не
                  каких денежных авансов, а предварительно детально всё
                  обсудить, договориться, и заключить письменный договор, между
                  специалистом и клиентом. Все наличные и безналичные расчеты
                  между клиентом и исполнителем вне сайта осуществляются
                  сторонами напрямую без участия Gorc-ka.
                </Typography>
                <Typography mt={2}>
                  1.19. Указанная информация об услугах, условиях и стоимости их
                  оказания, указанная на сайте Gorc-ka, носит справочный
                  характер. Конечную стоимость услуги необходимо уточнять у
                  выбранного специалиста.
                </Typography>
                <Typography>
                  Все претензии, вопросы, недовольства, связанные с оказанными
                  услугами исполнителя должен направить заказчику в
                  адрес самого исполнителя, по любым доступным контактным данным
                  указанными в карточке исполнителя.
                </Typography>
                <Typography>
                  Gorc-ka не может гарантировать 100% соответствия сайта
                  целям и ожиданиям пользователя, идеальную и безошибочную
                  работу сайта. Любой зарегистрированный пользователь должен
                  самостоятельно оценивать все риски, связанные с использованием
                  информации, включая оценку надежности, полноты или полезности,
                  а также их соответствие законодательству.
                </Typography>
                <Typography>
                  Gorc-ka не является участником правоотношений,
                  устанавливаемых между клиентом и исполнителем по поводу
                  оказания услуг, не выполняет в ней функции посредничества, не
                  отвечает за исполнение сторонами своих обязанностей, и не
                  оказывает консультаций по правовым, финансовым и
                  организационным вопросам.
                </Typography>
                <Typography>
                  Клиент и исполнитель обязуются самостоятельно улаживать все
                  споры, возникающие в связи с заключением и исполнением сделки
                  по поводу предлагаемых и запрашиваемых услуг, в том числе
                  направлять претензии напрямую в адрес участника такой сделки.
                </Typography>
                <Typography mt={2}>
                  1.20. Gorc-ka оставляет за собой право на свое усмотрение
                  ограничить доступ исполнителя к сайту с использованием его
                  учетной записи или полностью заблокировать аккаунт, при
                  выявлении подозрительной активности на сайте, а также
                  применить к исполнителю иные меры с целью соблюдения
                  требований настоящих условий.
                </Typography>
                <Typography>
                  Gorc-ka оставляет за собой право ограничить доступ клиента
                  к сайту или заблокировать профиль клиента на сайте в случае
                  выявления подозрительных действий на сайте.
                </Typography>
                <Typography>
                  Любая информация, используемая на сайте Gorc-ka,
                  предназначена исключительно для личного некоммерческого
                  использования. Любое копирование информации с сайта,
                  воспроизведение, переработка, распространение, любое
                  использование в средствах массовой информации или в
                  коммерческих целях без предварительного письменного разрешения
                  Gorc-ka запрещается.
                </Typography>
                <Typography>
                  Любой пользователь соглашается с тем, что не будет
                  использовать информацию и контент, размещенные на сайте Лига
                  услуг, без разрешения Gorc-ka, в случаях, когда такое
                  использование не связано с целями совершения и исполнения
                  сделок. Запрещается использовать контактные данные исполнителя
                  или клиента без согласия исполнителя или клиента. По вопросам
                  и претензиям, связанным с использованием или невозможностью
                  использования сайта Gorc-ka, а также с возможным нарушением
                  законодательства или прав третьих лиц на сайте Gorc-ka,
                  пользователь может направить свое обращение через форму
                  обратной связи по адресу liga124@mail.ru
                </Typography>
                <Typography style={{ textAlign: "right" }}>
                  Дата публикации: 6 июня 2022 г.
                </Typography>
                <Box
                  style={{ display: "flex", justifyContent: "center" }}
                  mb={5}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Button color="success" variant="contained">
                      Принимаю
                    </Button>
                  </Link>
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      </Container>
    </div>
  );
};

export default TermsOfUsePage;
